<script>
	import { Promises } from '$lib/store.js';
	export let type = 'Site: chrome.com';
	export let data;
	let domain = type?.split(': ')[1];
	console.log('favicon', domain);
</script>

{#if data?.url && type?.split(': ')[0] === 'Site'}
	<img
		alt=""
		src={$Promises[domain]
			? '/logos/chrome.png'
			: `https://${
					$Promises['favicon:' + domain] ||
					`www.google.com/${
						domain?.includes('mail.google.com')
							? 'a/cpanel/gmail.com/images/favicon.ico'
							: 's2/favicons'
					}?sz=64&domain_url=`
			  }${
					$Promises['favicon:' + domain]
						? ''
						: $Promises[domain] ||
						  (domain &&
								type
									?.split(': ')[1]
									.split('.')
									.slice(type?.slice(-6).split('.').length > 2 ? -3 : -2)
									.join('.')) ||
						  (data?.title.includes('Untracked') || data?.url?.includes('devtools')
								? 'chrome'
								: data?.app) + '.com'
			  }`}
		class=" w-3.5 h-3.5 mr-1 m-px rounded-sm"
		on:error={(e) => {
			console.log(domain, e.target.src, $Promises[domain], $Promises['favicon:' + domain]);
			if (e.target.src.includes('chrome.com') || $Promises[domain]) return;
			// $Promises['favicon:' + domain] = undefined;
			$Promises[domain] = 'chrome.com';
		}}
		on:load={(e) => {
			if (e.target.src.includes('chrome.com') || $Promises[domain]) return;
			if (e.target.naturalWidth === 1 && !$Promises[domain] && !$Promises['favicon:' + domain]) {
				$Promises[domain] = 'chrome.com';
				e.target.src = 'https://www.google.com/s2/favicons?sz=64&domain_url=chrome.com';
				return;
			}
			if (
				e.target.naturalWidth === 16 &&
				!e.target.src.includes('chrome.com') &&
				!e.target.src.includes('virginatlantic') &&
				e.target.src !==
					'https://' +
						type
							?.split(': ')[1]
							.split('.')
							.slice(type?.slice(-6).split('.').length > 2 ? -3 : -2)
							.join('.') +
						'/favicon.ico' &&
				!$Promises[domain]
			) {
				console.log('setting favicon');
				$Promises['favicon:' + domain] =
					type
						?.split(': ')[1]
						.split('.')
						.slice(type?.slice(-6).split('.').length > 2 ? -3 : -2)
						.join('.') + '/favicon.ico';

				e.target.src =
					'https://' +
					type
						?.split(': ')[1]
						.split('.')
						.slice(type?.slice(-6).split('.').length > 2 ? -3 : -2)
						.join('.') +
					'/favicon.ico';
			}
		}}
	/>
{:else}
	{#await $Promises[data?.bundleId || data?.app] || ($Promises[data?.bundleId || data?.app] = fetch('https://icons.magicflow.workers.dev?app=' + (data?.app || '').toLowerCase() + (!data?.bundleId ? '' : '&bundleId=' + (data?.bundleId || '')))
				.then((r) => r.text())
				.catch((e) => console.error(e)))}
		<div class="w-3.5 h-3.5 mr-1 m-px rounded-md bg-gray-600 bg-opacity-20 animate-pulse" />
	{:then r}
		{#if !r || r.startsWith('No ')}
			<div class="w-3.5 h-3.5 mr-1 m-px rounded-md bg-gray-700 flex items-center justify-center">
				{(data?.app || data?.title)?.slice(0, 1)}
			</div>
		{:else}
			<img
				src={r}
				alt="app icon"
				class="{data?.app === 'Slack' ? 'scale-125' : ''} w-3.5 h-3.5 mr-1 m-px rounded-sm"
			/>
		{/if}
	{:catch e}
		<div class="w-3.5 h-3.5 mr-1 m-px rounded-md bg-gray-700 flex items-center justify-center">
			{(data?.app || data?.title)?.slice(0, 1)}
		</div>
	{/await}
{/if}

<style>
	img {
		filter: invert(1) hue-rotate(180deg) drop-shadow(0 0 0.5px #fff6) saturate(1.5) brightness(1.05)
			contrast(1.1) !important;
	}
	@media (prefers-color-scheme: dark) {
		img {
			filter: drop-shadow(0 0 0.5px #fff6) saturate(1.5) brightness(1.05) contrast(1.1) !important;
		}
	}
</style>
